import React, { useEffect, useRef, useState } from "react";
import useBasket from "../../Context/BasketContext/useBasket";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import PageHeader from "../../Util/PageHeader";
import Basket from "../../Basket/Basket";
import IsLoggedIn from "../../Util/IsLoggedIn";
import useCustomerMemberships from "../../Context/CustomerMembershipsContext";
import GenerateBasketFooterLink from "../../Util/GenerateCheckoutLink";

const CheckoutIntegrate = () => {
    const { setBasketId, renewBasket } = useBasket();
    const { basketId }: { basketId: string | undefined } = useParams();
    const { setRedirectTo } = useAppContext();
    const [basketFooterLink, setBasketFooterLink] = useState("");
    const buttonRef = useRef(null);
    const match = useRouteMatch();

    const isLoggedIn = IsLoggedIn();

    const {
        basketData,
        areThereBasketItems,
        areThereBasketActivities,
        areThereBasketMemberships,
        areBookingNamesRequired
    } = useBasket();
    const { hasCustomerMemberships } = useCustomerMemberships();

    useEffect(() => {
        console.log("we have items");

        const link = isLoggedIn
            ? GenerateBasketFooterLink(
                  areThereBasketActivities,
                  areBookingNamesRequired,
                  hasCustomerMemberships,
                  match.path
              )
            : "/log-in";

        setBasketFooterLink(link);
        console.log("basketFooterLink", basketFooterLink);

        window.location.href = link;
    }, [areThereBasketItems]);

    if (basketId) {
        console.log("found bid", basketId);
        setBasketId(basketId ?? "");

        try {
            renewBasket({ variables: { basketId: basketId ?? "" } });
        } catch (e) {
            console.log("error renewing basket", e);
        }
    }
    //  else {
    //     window.location.href = "/log-in/?nobasket";
    // }

    return (
        <div className="fw">
            <PageHeader title="Activities check-out" />
            <div className="pw">
                <div className="clearfix" />
                <div className="content fw">
                    <div className="two-thirds-container">
                        <div className="m-checkin a-box v-box-secondary">
                            <p>Link: {basketFooterLink}</p>
                            <Link to={"/check-out"}>
                                <button
                                    ref={buttonRef}
                                    className="m-btn fw"
                                    id="checkout-button"
                                    data-testid="checkout-button"
                                >
                                    Continue to Payment
                                </button>
                            </Link>
                            <div className="clearfix" />
                        </div>
                    </div>
                    <aside
                        id="basket-scroll"
                        className="sidebar third-container right"
                    >
                        <Basket
                            isCheckout={true}
                            shouldShowMembershipWarning={true}
                        />
                    </aside>
                </div>
            </div>
        </div>
    );
};

export default CheckoutIntegrate;
